<template>
    <div class="section my-5 py-md-5 px-xl-5">
        <div class="secondary-heading text-center">{{ $t(heading) }}</div>
        <h1 class="text-center mb-5">{{ $t('globalGallery') }}</h1>
        <div v-if="images.length !== 0 && oneSecOffset">
        <div class="container-fluid px-4 img-load-opacity">
            <div class="row">
                <SwiperLightBox :images="images" />
            </div>
        </div>
        </div>
        <div v-else>
            <div class="loader mx-auto text-center"></div>
        </div>
        <div v-if="error" class="text-center" v-html="$t('web2023TryAgainLater')"></div>
    </div>
</template>

<script setup>
    /* Lazy load imgs */
    import VLazyImage from "v-lazy-image";
    /* API */
    import getImagesFromStaticByFolder from '@/composables/getImagesFromStaticByFolder.js'
    /* LightBox */
    import SwiperLightBox from '@/components/testSwiperLightBox.vue'
    import { onMounted, ref } from "vue";

    const props = defineProps({
        heading: String,
        imgsFolderPath: String,
    });

    const { images, error, loadImages } = getImagesFromStaticByFolder()
    loadImages(props.imgsFolderPath)
    /* console.log('photoGallery.vue images', images) */

    const oneSecOffset = ref(false)
    onMounted(() => {
        setTimeout(() => {
           oneSecOffset.value = true 
        }, 500);
    })
</script>

<style>

.img-load-opacity .v-lazy-image {
    opacity: 0;
    transition: opacity 2s;
    width: 100%;
}
.img-load-opacity .v-lazy-image-loaded {
    opacity: 1;
}

.img-wrap img {
    object-fit: cover;
    cursor: pointer;
}



@media (min-width: 350px) and (max-width: 991px)  {
    .col-xs-6-mine {
      width: 50% !important;
    }

    .img-wrap img {
        height: calc(44vw / 1.7);
    }
}

@media (min-width: 992px) {
    .img-wrap img {
        height: calc(32vw / 1.7);
    }
}

@media (min-width: 1200px) {
    .img-wrap img {
        height: calc(23vw / 1.7);
    }
}
</style>