<template>
    <section id="after-movies">
        <div class="secondary-heading text-center">ESSENS ANNIVERSARY 2023</div>
        <h1 class="text-center mb-5"> {{ $t('globalVideo') }} </h1>
        <div id="after-movies-container" class="container px-5 position-relative">
             <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/OHF-E4QS4JY" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div> 
        </div>
    </section>
</template>

<style>
    #after-movies {
        background-color: var(--grey-bg);
    }
</style>