<template>
    <section id="program" class="features-area">
        <div class="container">
            <div class="secondary-heading text-center">ESSENS ANNIVERSARY 2023</div>
            <h1 class="text-center mb-5"> {{ $t('globalProgram') }} </h1>
            <div class="row d-flex justify-content-center">               
                    <div class="col-md-6 col-12">
                       <h2>{{ $t('globalLuxuryStay') }}</h2>
                       <br>
                       <p>{{ $t('anivLuxuryStayT') }}</p>
                    </div>
                    <div class="col-md-6 col-12">
                        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" style="width: 100%;">
                            <source src="@/assets/hotel.mp4" type="video/mp4">
                        </video> 
                    </div>            
            </div>
            <div class="row d-flex justify-content-center" style="padding-top: 50px;">
               
               <div class="col-md-6 col-12">
                  <h2>{{ $t('globalEnrichedPr') }}</h2>
                  <br>
                  <p>{{ $t('anivEnrichedPrT') }}</p>
               </div>
               <div class="col-md-6 col-12 order-lg-first">
                    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" style="width: 100%;">
                        <source src="@/assets/program.mp4" type="video/mp4">
                    </video> 
               </div>
         
       </div>
       <div class="row d-flex justify-content-center" style="padding-top: 50px;">
               
               <div class="col-md-6 col-12">
                  <h2>{{ $t('globalGalaEvening') }}</h2>
                  <br>
                  <p>{{ $t('anivGalaEveningT') }}</p>
               </div>
               <div class="col-md-6 col-12">
                    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" style="width: 100%;">
                        <source src="@/assets/gala.mp4" type="video/mp4">
                    </video> 
               </div>
         
       </div>
        </div>
    </section>
</template>

<script setup>
    import { useI18n } from 'vue-i18n';
    const { locale } = useI18n()

</script>

<style>
    #program {
        background-color: var(--grey-bg);
    }

    #program .col-md-6 {
    align-self: center;
    }

    .features-area p:first-of-type {
        margin-bottom: 0;
        text-align: justify;
        padding-bottom: 10px;
    }
</style>