const menuData = [
    { name: 'globalHome', link: '/' },
    { name: 'globalEvent', link: '/#about-event' },
    { name: 'globalProgram', link: '/#program' },
    { name: 'globalGallery', link: '/#photo-gallery' },
    { name: 'globalVideo', link: '/#after-movies' },

    /* { isNotKey: true, name: 'Test swiper', link: '/test_swiper' },
    { isNotKey: true, name: 'gallery UK', link: '/gallery/uk' }, */
]
export default menuData