<template>
    <section id="about-event" class="container">
        <div class="secondary-heading">About</div>
        <h1>ESSENS ANNIVERSARY 2023</h1>
        <p>{{ $t('anivAboutText1') }}</p>
       <!--  <h4 class="mt-5">{{ $t('web2023AboutT2') }}</h4>
        <p>{{ $t('web2023AboutT3') }}</p> -->
        <div class="icons-about">
            <div class="row">
                <div v-for="icon in iconsWrap" :key="icon" class="icon-col col-lg-4">
                    <img :src="icon.imgSrc" :alt="headingKey+'-icon'">
                    <h4>{{ $t(icon.headingKey) }}</h4>                           
                        <p v-html="$t(icon.textKey)"></p>                   
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import { useI18n } from "vue-i18n";
    const { locale } = useI18n()

    /* TODO imgSrc */
    const iconsWrap = [
        { imgSrc: 'https://static.essensworld.com/images/anniv_23/web/zazitky.png', headingKey: 'globalZazHeader', textKey: 'anivZazText'},
        { imgSrc: 'https://static.essensworld.com/images/anniv_23/web/oceneni.png', headingKey: 'globalOcenHeader', textKey: 'anivOcenText' },
        { imgSrc: 'https://static.essensworld.com/images/anniv_23/web/inspirace.png', headingKey: 'globalInsHeader', textKey: 'anivInsText' },
    ];
</script>

<style>
    #about-event {
        text-align: center;
    }

    #about-event h1 {
        margin-bottom: 3rem;
    }

    /* #about-event p {
        margin-bottom: 2rem;
    } */

    .icons-about {
        margin-top: 3rem;
    }

    #about-event .icon-col {
        padding: 30px;
    }

    .icon-col h4 {
        margin-bottom: 1rem;
        transition: .3s;
    }

    .icon-col img {
        margin-bottom: 35px;
        padding: 20px 23px;
        max-width: 100px;
        display: inline-block;
        color: var(--primary);
        border: 1px solid var(--primary);
        font-size: 65px;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        -o-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;
    }

    .icon-col:hover img {
        border: 1px solid var(--secondary);
    }

    .icon-col:hover h4 {
        color: var(--secondary);
    }


    @media (min-width: 992px) {
        .icon-col img {
            max-width: 150px;
            padding: 36px 41px;
        }
    }
</style>