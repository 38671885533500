import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import TestApi from '@/views/TestApi.vue'
import TestSwiper from '@/views/TestSwiper.vue'
import GalleryProgram from '@/views/gallery/GalleryProgram.vue'
import GalleryGala from '@/views/gallery/GalleryGala.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/test_api',
    name: 'testApi',
    component: TestApi
  },
  {
    path: '/test_swiper',
    name: 'testSwiper',
    component: TestSwiper
  },
  {
    path: '/gallery/program',
    name: 'GalleryProgram',
    component: GalleryProgram
  },
  {
    path: '/gallery/gala',
    name: 'GalleryGala',
    component: GalleryGala
  },
  {
    path: '/:CatchAll(.*)',
    name: 'NotFound',
    component: HomeView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    /* console.log('router.. to=', to, 'router.. to.hash=', to.hash) */
    if (to.hash) {
      // Najdeme cílový element podle identifikátoru
      const element = document.querySelector(to.hash);
      if (element) {
        console.log('route.. elemnt=', element)
        // Posuneme stránku na cílový element
        element.scrollIntoView({ behavior: 'smooth'});
      }
      return
    } else {
      // Standardní chování routeru
      if (savedPosition) {
        return savedPosition
      } else {
        return { top: 0 }
      }
    }
  },
})

/* router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  loadLanguageAsync(lang).then(() => next())
}) */

export default router